import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class Header {
    constructor() {
        Header.swiper_banner()
        Header.sticky()
        Header.gtranslate()
        Header.menu()
    }

    static async swiper_banner() {
        const swiperItem = '[data-swiper="banner"]'
        const slideItem = '[data-swiper="banner"] > div'

        const options = {
            slidesPerView: 'auto',
            autoplay: {
                delay: 5000,
            },
            speed: 1500,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: '#swiper-banner-pagination',
                type: 'bullets',
            },
            navigation: {
                nextEl: '#swiper-banner-next',
                prevEl: '#swiper-banner-prev',
            },
            mousewheel: {
                forceToAxis: true,
            },
            grabCursor: true,
            loop: true,
        }

        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

        await Swiper.create('[data-swiper="banner"]', options)
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 120 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0 || $('.error404').length > 0) {
                full = true
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    Header.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    Header.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')

        if (color === 'banner') {
            logo.attr('src', IRISCollectionCustomer.logoBannerUrl)
        } else if (color === 'classic') {
            logo.attr('src', IRISCollectionCustomer.logoUrl)
        }
    }

    static menu() {
        const triggerMenu = '[data-trigger="wpiris-menu-toggle"]'
        const trigger = '[data-trigger="wpiris-menu-list-toggle"]'
        const target = '[data-target="menu-list-item"]'

        $(triggerMenu).on('click', () => {
            $('body').toggleClass('menu-open')
            $(target).removeClass('is-active')
        })

        $(trigger).on('click', function () {
            if (!$(this).closest(target).hasClass('is-active')) {
                $(target).removeClass('is-active')
                $(this).closest(target).addClass('is-active')
            } else {
                $(target).removeClass('is-active')
            }
        })
    }

    static gtranslate() {
        const current_lang = '[data-id="wpiris_current-lang"]'
        const list_lang_item = '[data-id="wpiris_lang-list"] > a'

        //Init select lang
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').replace(/-.*/gi, '')
                $(current_lang).text(current_language)
                smartsupp('language', current_language) //chat language
                //Remove current language on list (init)
                $(list_lang_item).each(function () {
                    if ($(this).text() === current_language) {
                        $(this).hide()
                    }
                })
            }, 300)
        })

        //Change Header Language on click
        $(list_lang_item).on('click', function () {
            $(list_lang_item).show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }
}
